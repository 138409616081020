
import axios from 'axios';
import { get } from 'lodash';
import { URL } from 'config/config';
import { showAlert } from './alert';

export const FETCH_LOCATION_GROUP_LOADING = 'FETCH_LOCATION_GROUP_LOADING';
export const FETCH_LOCATION_GROUP_SUCCESS = 'FETCH_LOCATION_GROUP_SUCCESS';
export const FETCH_LOCATION_GROUP_ERROR = 'FETCH_LOCATION_GROUP_ERROR';
export const FETCH_LOCATION_GROUP_DETAILS = 'FETCH_LOCATION_GROUP_DETAILS';


const setLocationGroupsLoading = (value = true) => ({
    type: FETCH_LOCATION_GROUP_LOADING,
    payload: value
});
const setLocationGroups = payload => ({
    type: FETCH_LOCATION_GROUP_SUCCESS,
    payload
});
const setLocationGroupError = payload => ({
    type: FETCH_LOCATION_GROUP_ERROR,
    payload
});
const setLocationGroupDetails = payload => ({
    type: FETCH_LOCATION_GROUP_DETAILS,
    payload
});


export const fetchLocationGroups = () => async dispatch => {
    dispatch(setLocationGroupsLoading(true));
    try {

        const response = await axios.get(URL.LOCATION_GROUPS);
        if (response && response.data.success) {
            const data = get(response, 'data.objects', []);
            dispatch(setLocationGroups(data));
            dispatch(setLocationGroupsLoading(false));
            return data
        }
    } catch (err) {
        dispatch(setLocationGroupError(err));
        dispatch(setLocationGroupsLoading(false));
    };
}

export const createLocationGroup = (payload) => async dispatch => {
    try {
        const response = await axios.post(URL.LOCATION_GROUPS, payload);
        if (response && response.data.success) {
            const data = get(response, 'data.object', []);
            dispatch(fetchLocationGroups())
            return data
        } else if (response?.data?.message) {

            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const fetchLocationGroupById = (locationGroupId) => async dispatch => {
    dispatch(setLocationGroupsLoading(true));
    try {
        const response = await axios.get(`${URL.LOCATION_GROUPS}/${locationGroupId}`);
        if (response && response.data.success) {
            const data = get(response, 'data.object', {});
            dispatch(setLocationGroupDetails(data));
            dispatch(setLocationGroupsLoading(false));
            return data
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const editLocationGroup = (payload) => async dispatch => {
    try {
        const response = await axios.put(URL.LOCATION_GROUPS, payload);
        if (response && response.data.success) {
            const data = get(response, 'data.object', []);
            dispatch(fetchLocationGroups())
            localStorage.setItem('syncState_locationgroup', JSON.stringify(data)); //To monitor changes to the locations group (in other tabs) and refresh location group details
            return data
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const deleteLocationGroup = (locationGroupId) => async dispatch => {
    try {
        const response = await axios.delete(URL.LOCATION_GROUPS + `/${locationGroupId}`);
        if (response && response.data.success) {
            dispatch(fetchLocationGroups())
            return true
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}