import axios from 'axios'
import { get, reduce } from 'lodash'

import { URL } from 'config/config'
import { EVENT_TYPE } from 'constants/Calendar.constants'
import { setTmpService } from 'actions/tmpServices'
import { tmp_service } from "constants/TmpServices.constants"
import { delay } from 'commonFunctions'
import { handleRequest } from 'utils/apiHelpers'
import { showAlert } from './alert'
import { generatePayload } from 'views/Calendar/helpers'

export const CALENDAR_LOADING = 'CALENDAR_LOADING';
export const FETCH_EVENTS_BY_PROVIDERS_SUCCESS = 'FETCH_EVENTS_BY_PROVIDERS_SUCCESS';
export const FETCH_EVENTS_BY_RANGE_PROVIDERS_SUCCESS = 'FETCH_EVENTS_BY_RANGE_PROVIDERS_SUCCESS';
export const FETCH_PROVIDER_BY_BUSINESS_ID_SUCCESS = 'FETCH_PROVIDER_BY_BUSINESS_ID_SUCCESS';
export const CREATE_CALENDAR_EVENT_SUCCESS = 'CREATE_CALENDAR_EVENT_SUCCESS';
export const CHECK_OVERLAP_SUCCESS = 'CHECK_OVERLAP_SUCCESS';
export const CREATE_CALENDAR_EVENT_FAILURE = 'CREATE_CALENDAR_EVENT_FAILURE';
export const FETCH_SLOTS_BY_TMP_SERVICE_LOADING = 'FETCH_SLOTS_BY_TMP_SERVICE_LOADING';
export const FETCH_SLOTS_BY_TMP_SERVICE_SUCCESS = 'FETCH_SLOTS_BY_TMP_SERVICE_SUCCESS';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';

const calendarLoading = isLoading => ({
  type: CALENDAR_LOADING,
  isLoading
});

const setTmpServicesLoading = payload => ({
  type: tmp_service.FETCH_TMP_SERVICES_LOADING,
  payload
});

export const fetchEventsByProvidersSuccess = calendarData => ({
  type: FETCH_EVENTS_BY_PROVIDERS_SUCCESS,
  calendarData
});

export const fetchProvidersByBusinessIdSuccess = providers => ({
  type: FETCH_PROVIDER_BY_BUSINESS_ID_SUCCESS,
  providers
});

const createEventSuccess = newEvent => ({
  type: CREATE_CALENDAR_EVENT_SUCCESS,
  newEvent
});


export const fetchProvidersByBusinessId = () => dispatch => {
  dispatch(calendarLoading(true));
  return axios
    .get(`${URL.FETCH_PROVIDERS_BY_BUSINESS_ADMIN_ID}/`)
    .then((response) => {
      if (response && response.data.success) {
        const providers = get(response, 'data.objects', []);
        dispatch(fetchProvidersByBusinessIdSuccess(providers));
      }
    })
    .finally(() => { dispatch(calendarLoading(false)); });
};

const fetchEventsByProviderId = providerId => dispatch => {
  dispatch(calendarLoading(true));

  const fetchEvents = [];
  fetchEvents.push(axios.get(`${URL.FIND_NORMAL_EVENTS_BY_PROVIDER_ID}/${providerId}`));
  fetchEvents.push(axios.get(`${URL.FIND_TMP_EVENTS_BY_PROVIDER_ID}/${providerId}`));
  fetchEvents.push(axios.get(`${URL.FIND_APPOINTMENTS_CUSTOMER_EVENTS_BY_PROVIDER_ID}/${providerId}`));

  Promise.all(fetchEvents)
    .then((eventsResp) => {
      const tmpEvents = reduce(eventsResp, (acc, resp) => {
        const listEvent = get(resp, 'data.objects', []);
        return acc.concat(listEvent.map(e => ({
          ...e,
          providerId: resp.config.url.split('/').slice(-1)[0],
        })));
      }, []);
      const events = tmpEvents.map((e, index) => ({
        id: e.id,
        providerId: e.providerId,
        title: e.title,
        type: e.type,
        slot: { startTime: e.istart, endTime: e.iend },
        raw: { resourceId: e.resourceId, tempServiceId: e.tempServiceId, phone: e.phone },
      }));

      dispatch(fetchEventsByProvidersSuccess(events));
    })
    .finally(() => { dispatch(calendarLoading(false)); });
};

// the below function solely exist because I can't be bothered with changing up createNewEvent function to converted to async await syntax
// or unifying the create and edit tempservice function calls to less convoluted
const delayedReFetchTmpServiceDetails = async (data, dispatch) => { //data or event
  await delay(1000)
  if (data.tempServiceId) {
    let scheduledServiceId = data.tempServiceId
    const response = await axios.get(`${URL.FIND_TMP_SERVICE_DETAIL_BY_TMP_SERVICE_ID}/${scheduledServiceId}`)
    if(response?.data?.object){
      dispatch(setTmpService(response.data.object))
    }
  } else {
    const scheduledServiceId = data.id
    const response = await axios.get(`${URL.FIND_TMP_SERVICE_DETAIL_BY_TMP_SERVICE_ID}/${scheduledServiceId}`)
    if(response?.data?.object){
      dispatch(setTmpService(response.data.object))
    }
  }
}

export const checkEventOverlap = (newEvent, callback = false ) =>(dispatch, getState) => {
  dispatch(calendarLoading(true));
  let api= URL.NORMAL_EVENT_OVERLAP;

  return axios
    .post(api, newEvent)
    .then(response =>{
      if(response){
        if(response.data.success)
          {
            dispatch(calendarLoading(false));
            return response
          }
      }
    })
}

export const checkScheduledServiceOverlap = (
  {
    ReqeuestData : addEventData,
    ProviderData : providers,
    payload
  }
) => async (dispatch) => {
  dispatch(calendarLoading(true))
  dispatch(setTmpServicesLoading(true))
  const JSONrequestBody = payload ? payload : generatePayload( addEventData, providers)
  const response = await axios.post(URL.CHECK_SCHEDULED_SERVICE_OVERLAP,JSONrequestBody)
  dispatch(calendarLoading(false))
  dispatch(setTmpServicesLoading(false))
  return response
}

export const checkScheduledServiceDeleteBookingOverlap = ( {
  ReqeuestData : id,
}, callback = false ) => async dispatch => {
  dispatch(calendarLoading(true))
  const [result] = await handleRequest(axios.get, [`${URL.CHECK_SCHEDULED_SERVICE_DELETE_BOOKING_OVERLAP}/${id}`]);
  dispatch(calendarLoading(false))
  if(callback){
    callback(result, id)
  }
  return result
}

export const createNewEvent = (newEvent, callback = false, isWizard=false) => (dispatch, getState) => {
  dispatch(calendarLoading(true));

  let api = URL.NORMAL_EVENT;

  if (newEvent.type === EVENT_TYPE.TMP_SERVICE) {
    api = URL.NEW_TMP_SERVICE;
  }

  if (newEvent.type === EVENT_TYPE.CUSTOMER_APPOINTMENT) {
    api = URL.APPOINTMENTS_CUSTOMER_EVENT;
  }

  if (newEvent.type === EVENT_TYPE.CANCEL) {
    api = URL.CANCEL_EVENTS;
  }
  return axios
    .post(api, newEvent)
    .then(response => {
      if (response) {
        const data = get(response, 'data.object', {});
        if (response.data.success) {
          const event = {
            ...data,
            type: data.type || newEvent.type
          };

          if (newEvent.type === EVENT_TYPE.TMP_SERVICE) {
            dispatch({
              type: tmp_service.FETCH_TMP_SERVICES_SUCCESS,
              payload: [...getState().tmpServices.list, data]
            });
            if(!isWizard)
              delayedReFetchTmpServiceDetails(data,dispatch)
          } else {
            dispatch(createEventSuccess({
              id: event.id,
              providerId: event.providerId,
              title: event.title,
              type: event.type,
              slot: { startTime: event.istart, endTime: event.iend },
              raw: { resourceId: event.resourceId, tempServiceId: event.tempServiceId, phone: event.phone },
              description: '',
            }));
            event.type === EVENT_TYPE.TMP_SERVICE && !isWizard && delayedReFetchTmpServiceDetails(event,dispatch)
          }
        }

        if(callback){
          callback(response);
        }
        if(isWizard){
          return response.data;
        }
      }
    })
    .finally(() => {
      dispatch(calendarLoading(false));
    });
};

export const setBookingSlots = (payload) => ({
  type: FETCH_SLOTS_BY_TMP_SERVICE_SUCCESS,
  payload
});

export const getSlotsByTmpServiceId = (tmpServiceId, bookingEventId) => async dispatch => {
  dispatch({ type: FETCH_SLOTS_BY_TMP_SERVICE_LOADING, payload: true });
  const [result] = await handleRequest(axios.get, [`${URL.FIND_AVAILABILITY_BY_TMP_SERVICE}/${tmpServiceId}`]);
  if (result) {
    dispatch(setBookingSlots({ bookingSlots: result, bookingEventId }));
  }
  dispatch({ type: FETCH_SLOTS_BY_TMP_SERVICE_LOADING, payload: false });
}

export const getSlotsByTmpServiceIdOnboarding = (tmpServiceId, bookingEventId) => async dispatch => {
  dispatch({ type: FETCH_SLOTS_BY_TMP_SERVICE_LOADING, payload: true });
  const [result] = await handleRequest(axios.get, [`${URL.FIND_AVAILABILITY_BY_TMP_SERVICE_ONBOARDING}/${tmpServiceId}`]);
  if (result) {
    dispatch(setBookingSlots({ bookingSlots: result, bookingEventId }));
  }
  dispatch({ type: FETCH_SLOTS_BY_TMP_SERVICE_LOADING, payload: false });
}

export const rescheduleBookingEvent = (bookingEventId, selectedSlotid) => async dispatch => {
  const rescheduleData = { eventId: bookingEventId, newAvailabilityId: selectedSlotid };
  dispatch(calendarLoading(true));
  const [result] = await handleRequest(axios.put, [URL.RESCHEDULE_BOOKING_EVENT, rescheduleData]);
  if (result) {
    dispatch(showAlert('success', 'The booking is rescheduled successfully!'));
  }
  dispatch(calendarLoading(false));
}

export const cancelBookingEvent = (bookingEventId, providerId) => async dispatch => {
  dispatch(calendarLoading(true));

  const [result] = await handleRequest(axios.delete, [`${URL.CANCEL_BOOKING_EVENT}/${bookingEventId}`]);
  if (result) {
    dispatch(showAlert('success', 'The booking has been cancelled'));
    if(providerId)
      dispatch(fetchEventsByProviderId(providerId));
    return true
  }
  return false
}

export const deleteEvent = (event) => async dispatch => {
  dispatch(calendarLoading(true));

  const url = event.type === EVENT_TYPE.CUSTOMER_APPOINTMENT ? URL.APPOINTMENTS_CUSTOMER_EVENT : URL.NORMAL_EVENT;

  const [result] = await handleRequest(axios.delete, [`${url}/${event.id}`]);

  if (result) {
    dispatch(showAlert('success', 'The booking is deleted successfully!'));
    dispatch({ type: DELETE_EVENT_SUCCESS, payload: event.id });
  }

  dispatch(calendarLoading(false));
}


export const fetchEventsByRangeProviders = (data) => async dispatch => {
  dispatch(calendarLoading(true));
  const res = await axios.post(URL.FETCH_CUSTOMER_FLOW_BOARD, data)
  let events = [];
  if (res && res.status === 200 && res.data.success) {
    let events =  res?.data?.object && res?.data?.object?.customerFlowDetailList ?  res.data.object.customerFlowDetailList :[];
    if (events) {
      dispatch(fetchEventsByRangeProvidersSuccess(events));
    }
  }
  dispatch(calendarLoading(false));
  return events;
};

export const fetchEventsByRangeProvidersSuccess = calendarEvents => ({
  type: FETCH_EVENTS_BY_RANGE_PROVIDERS_SUCCESS,
  calendarEvents
});
